// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aplicacoes-jsx": () => import("./../src/pages/aplicacoes.jsx" /* webpackChunkName: "component---src-pages-aplicacoes-jsx" */),
  "component---src-pages-aplicativos-jsx": () => import("./../src/pages/aplicativos.jsx" /* webpackChunkName: "component---src-pages-aplicativos-jsx" */),
  "component---src-pages-consultoria-jsx": () => import("./../src/pages/consultoria.jsx" /* webpackChunkName: "component---src-pages-consultoria-jsx" */),
  "component---src-pages-contato-jsx": () => import("./../src/pages/contato.jsx" /* webpackChunkName: "component---src-pages-contato-jsx" */),
  "component---src-pages-ecommerce-js": () => import("./../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infraestrutura-jsx": () => import("./../src/pages/infraestrutura.jsx" /* webpackChunkName: "component---src-pages-infraestrutura-jsx" */),
  "component---src-pages-servicos-js": () => import("./../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sobre-jsx": () => import("./../src/pages/sobre.jsx" /* webpackChunkName: "component---src-pages-sobre-jsx" */),
  "component---src-pages-solucoes-js": () => import("./../src/pages/solucoes.js" /* webpackChunkName: "component---src-pages-solucoes-js" */),
  "component---src-pages-web-jsx": () => import("./../src/pages/web.jsx" /* webpackChunkName: "component---src-pages-web-jsx" */)
}

